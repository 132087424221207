<template>
    <BaseLayout>
        <div class="ol-addcart" id="cart" hidden="true">
            <figure>
                <img :srcset="require('@/assets/images/og-addtocart.svg')" alt="Berhasil Ditambahkan" title="Berhasil Ditambahkan" />
            </figure>
            <p>Berhasil dimasukkan ke keranjang</p>
        </div>
        <div class="ol-addcart" id="wishlist" hidden="true">
            <figure>
                <img :srcset="require('@/assets/images/og-addtocart.svg')" alt="Berhasil Ditambahkan" title="Berhasil Ditambahkan" />
            </figure>
            <p>Berhasil ditambahkan ke wishlist</p>
        </div>
        <div class="ol-addcart" id="copy_url" hidden="true">
            <i class=""></i> Tautan Berhasil tersimpan
        </div>
        
        <DetailLoader v-if="loading.detail" />

        <section  class="ol-product ol-product-detail">
            <div v-if="!loading.detail" class="ol-wrapper ol-wrapper--large">
                <div class="ol-breadcrumb" style="padding-bottom: 30px !important;">
                    <a href="javascript:void(0)" class="ol-breadcrumb__link">Home</a> > <a href="#" class="ol-breadcrumb__link">{{product.name}}</a>
                    <button  type="button" @click="showPopup()" class="ol-btn ol-btn-wishlist" style="float:right;"> <i class="fas fa-map-pin ol-fa"></i> {{location ? location.city_name : 'Pilih Lokasi'}} </button>
                </div>
                <div class="ol-bundling-detail">
                    <div class="ol-bundling-detail-wrapper">
                        <div class="ol-bundling-img">
                            <div class="ol-bundling-slide">
                                <div  v-for="image in product.r_uni_product_images " :key="image.id+'-bundle'" :class="'ol-bundling-slide-item '+' ol-bundling-slide-item-'+image.id">
                                    <img :srcset="image.image_url">
                                </div>
                            </div>
                        </div>
                        <div class="ol-bundling-desc">
                            <h2>{{product.name}}</h2>
                            <p class="bundlingDesc">{{product.description}} </p>
                            <div>
                                <br>
                                <div class="ol-home-product__content-item-list--item-price">
                                    <span v-if="activeBundle && activeBundle.r_uni_product_variant_prices.more_discount && activeBundle.r_uni_product_variant_prices.more_discount != '0%' && activeBundle.r_uni_product_variant_prices.ho_more_rp != 'Rp0' " class="ol-home-product__content-item-list--item-price-real" id="harga_asli">{{activeBundle.r_uni_product_variant_prices.ho_more_rp}} <br></span>
                                    
                                    <small class="price_barang ol-home-product__content-item-list--item-price-discount" style="font-size: 28px">{{activeBundle ? activeBundle.r_uni_product_variant_prices.more_discount_price_rp : "Rp0" }}
                                        <i v-if="activeBundle && activeBundle.r_uni_product_variant_prices.more_discount && activeBundle.r_uni_product_variant_prices.more_discount != '0%'" class="ol-home-product__content-item-list--item-price-discount-label" id="diskon">{{activeBundle.r_uni_product_variant_prices.more_discount}} OFF</i>
                                    </small>
                                </div>
                                <br>
                                <div class="ol-product-content-detail__desc-spec-text">
                                    <div>
                                        <a v-for="variant in product.r_uni_product_variants" :key="variant.id+'-bundle-image-variant'" href="javascript:void(0)" @click="setVariant(variant)" :id="variant.id" class="ol-product-content-detail__desc-spec-text-type">{{variant.name_long}}</a>
                                    </div>
                                </div>
                            </div>
                            <a href="#ulasbundling" class="bundling-nav-ulas">
                                <div class="row ulasBundling">
                                    <div>
                                        <i class="fas fa-star fa-lg" style="color:gold;"></i>
                                        <i class="fas fa-star fa-lg" style="color:gold;"></i>
                                        <i class="fas fa-star fa-lg" style="color:gold;"></i>
                                        <i class="fas fa-star fa-lg" style="color:gold;"></i>
                                        <i class="fas fa-star fa-lg" style="color:gold;"></i>

                                    </div>
                                    <div class="review-overall-new">
                                        <p>{{product.rating}}</p>
                                    </div>
                                </div>
                            </a>
                            <div class="ol-product-content-detail__desc-share">
                                <span style="min-width: auto;">Bagikan</span>
                                <div>
                                    <a href="javascript:void(0)" @click="fb_share('The Lost World Castle Tetap Buka Meski Merapi Semburkan Awan Panas',currentUrl())" class="ol-product-content-detail__desc-share-link" target="_blank" title="Facebook">
                                        <img :srcset="require('@/assets/images/og-facebook.svg')" alt="Facebook">
                                    </a>
                                    <a :href="'https://api.whatsapp.com/send/?phone&text='+product.name+'+-+20Olympic+-+'+currentUrl()" class="ol-product-content-detail__desc-share-link" target="_blank" title="Whatsapp">
                                        <img :srcset="require('@/assets/images/og-whatsapp.svg')" alt="Whatsapp">
                                    </a>
                                    <a href="javascript:void(0)" @click="copyLinkWa()" class="ol-product-content-detail__desc-share-link" title="Copy Link">
                                        <img :srcset="require('@/assets/images/og-copylink.svg')" alt="copy link" />
                                    </a>
                                    <a href="javascript:void(0)" @click="showPopup2()" class="ol-product-content-detail__desc-share-link" style="font-size: 30px;color: bisque;padding-bottom:5px;">
                                        <!-- <i class="fas fa-comment-alt-lines ol-fa"></i> -->
                                        <i class="fas fa-comment-alt ol-fa" style="margin-bottom:5px;"></i>
                                    </a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="row bundleSeparator">
                        <div class="col bundleSeparatorLine">
                        </div>
                        <div class="bundleSeparatorText">
                            <p>Produk di dalam bundling ini</p>
                        </div>
                        <div class="col bundleSeparatorLine">
                        </div>
                    </div>

                </div>
                <!-- LOOPING -->
                <div v-for="bundle in list" :key="bundle.id+'-item-bundle'" class="ol-product-content ol-product-content-detail add-fix">

                    <div class="ol-product-content-detail__images">
                        <div class="ol-product-content-detail__images-list">

                            <div class="ol-product-content-detail__images-list-single">
                                <figure v-for="image in bundle.r_uni_product_images" :key="image.id" class="ol-product-content-detail__images-list-single-figure" :data-src="image.image_url">
                                    <div class="ol-product-img-container">
                                        <img class="og-brand-bearland" :srcset="image.image_url" alt="" title="">
                                        <div class="ol-product-watermark">
                                            <img :src="bundle.brand_url">
                                        </div>
                                    </div>
                                </figure>
                            </div>
                            <div class="ol-product-content-detail__images-list-group">
                                <figure v-for="image in bundle.r_uni_product_images" :key="'detail-'+image.id" :id="'detail-'+(image.id).toString()" class="ol-product-content-detail__images-list-group-figure item-detail-images">
                                    <img :srcset="image.image_url" alt="" title="">
                                </figure>
                            </div>

                        </div>
                    </div>

                    <div class="ol-product-content-detail__desc">
                        <h3 class="ol-home-product__content-item-list--item-title">
                            {{ bundle.product_name }}
                        </h3>


                        <div class="ol-home-product__content-item-list--item-price" style="margin-bottom: 0;">
                            <span v-if="bundle.price_normal" class="ol-home-product__content-item-list--item-price-real" id="harga_asli">
                                {{bundle.price_normal}}
                            </span>
                            <div style="display: flex; align-items: center;">
                                <small class="price_barang ol-home-product__content-item-list--item-price-discount">{{bundle.price_range}}
                                    <i v-if="bundle.discount_max && bundle.discount_max != '0%'" class="ol-home-product__content-item-list--item-price-discount-label" id="diskon">{{bundle.discount_max}} OFF</i>
                                </small>
                            </div>
                        </div>

                        <div class="ol-product-content-detail__desc-spec" style="margin-top: 10px;">

                            <div>
                                <div>
                                    <p style="font-weight: bold;">Pilih Variasi</p>
                                    <table class="table" style="padding-left: 10px;">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="i in parseInt(bundle.qty)" :key="i+'-bundle'">
                                                <th scope="row">{{i}}</th>
                                                <td>
                                                    <div style="display: flex; align-items: center; width: 400px; gap: 15px;">
                                                        <form >
                                                            <select class="bundling-select-box" @change="checkingStock($event,bundle,i)">
                                                                <option value="">-- Pilih Opsi --</option>
                                                                <option v-for="variant in bundle.r_uni_product_variants" :key="variant.id+'-variant-option'" :value="variant.id">{{variant.name_short}}</option>
                                                            </select>
                                                        </form>
                                                        <i style="display:none" class="fas fa-spinner fa-pulse ol-fa" :id="'spinner-out-stock-'+bundle.id+'-'+i"></i>
                                                        <p class="ol-bundling-stock-warning" :id="'alert-out-stock-'+bundle.id+'-'+i">Stok Habis</p>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="ol-alert ol-alert--danger alert-mandatory-varian" :id="'alert-mandatory-varian-'+bundle.id" style="display: none">
                                <i class="ol-fa fas fa-times"></i>Silakan pilih varian terlebih
                                dahulu
                            </div>

                            <div class="ol-product-content-detail__action bundlingHalamanProdukButton">
                                <!-- <a href="cart.html" class="ol-btn"><i class="fas fa-shopping-cart ol-fa"></i>
                                    Masukkan
                                    Keranjang</a> -->
                                <router-link :to="'/product/'+bundle.product_slug" target="_blank" rel="noopener noreferrer" class="ol-btn ol-btn-productpage "> Halaman Produk</router-link>
                            </div>


                        </div>

                    </div>

                </div>
                <div class="row bundleSeparator">
                    <div class="col bundleSeparatorLine">
                    </div>
                    <div class="col bundleSeparatorLine">
                    </div>
                </div>
                <!-- END LOOPING -->

                <div class="bundling-overview">

                    <div class="bundling-overview-price">

                        <h2 class="bundling-total-belanja">Total Belanja:</h2>
                        <div>
                            <p class="ol-home-product__content-item-list--item-price-real">{{ activeBundle ? activeBundle.r_uni_product_variant_prices.ho_more_rp : "Rp0" }}</p>
                        </div>
                        <h2 class="bundlingOverviewPrice">{{ activeBundle ? activeBundle.r_uni_product_variant_prices.more_discount_price_rp : "Rp0" }}
                        </h2>
                        <small style="font-size: small;">Kamu menghemat sebesar <span style="font-weight: bold; color:  #c13b37 ;">{{ activeBundle ? activeBundle.r_uni_product_variant_prices.saving_rp : "Rp0" }}</span></small>

                    </div>
                    <div class="">

                        <a class="ol-btn" id="button-bundling" @click="showBundlePopup()"><i class="fas fa-clipboard-list ol-fa"></i>
                            Periksa Belanjamu</a>
                    </div>
                </div>

                <div class="ol-product-content ol-product-content-detail">
                    <ReviewList detail="bundle" />
                </div>
            </div>
            <div v-if="others.length > 0" class="ol-wrapper ol-wrapper--large">
                <div class="ol-product-content ol-product-related add-fix">
                    <h2 class="ol-section-title">
                        <span>BUNDLING <strong>LAINNYA</strong></span>
                            <i></i>
                    </h2>
                    <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loading.others"/>
                    <ProductList :products="others" v-if="!loading.others" /> 
                </div>
            </div>
            <div class="ol-wrapper ol-wrapper--large">
                <div class="ol-product-content ol-product-related add-fix">
                    <h2 class="ol-section-title">
                        <span>PRODUK <strong>TERAKHIR DILIHAT</strong></span>
                            <i></i>
                    </h2>
                    <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loading.lastSeen"/>
                    <CarouselProduct :products="lastSeen" v-if="!loading.lastSeen" carouselKey="lastseen" /> 
                </div>
            </div>
        </section>

		<Popup title="Pilih Lokasi">
            <div class="ol-input-box ol-input-box--full">
                <label class="ol-input-label">Kota/Kabupaten</label>
                <select id="cities" class="ol-input ol-input-select select2"> </select>
            </div>
            <div class="ol-input-box ol-input-box--half">
                <button type="button" name="" @click="saveLocation()" class="ol-btn">Simpan</button>
            </div>
        </Popup>
        <Popup title="Kirim pesan" id="pesanan">
            <Alert v-show="input.message !=null" :type="input.type" :message="input.message"/>
            <div class="ol-input-box ol-input-box--full">
                <!-- <label class="ol-input-label">Chat</label> -->
                <textarea class="ol-input"  placeholder="Enter text here..." v-model="input.pesan" rows="4" style="height:unset"></textarea>
            </div>
            <div class="ol-input-box ol-input-box--half">
                <button v-show="input.loading" type="button" id="button_wishlist" style="pointer-events: none;" class="ol-btn ol-btn-wishlist"> <i class="fas fa-spinner fa-pulse ol-fa"></i> Kirim</button>
                <button v-show="!input.loading" type="button" name="" @click="chatProduct()" class="ol-btn"><i class="fas fa-paper-plane  ol-fa"></i>  Kirim</button>
            </div>
        </Popup>
        <BundlePopup title="Rincian Bundling">
            <table class="table2">
                <thead>
                    <tr>
                        <th scope="col" style="width: 10%;">Produk</th>
                        <th scope="col"></th>
                        <th scope="col">Jumlah</th>
                        <!-- <th scope="col">Subtotal</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="detail in productsSummary" :key="detail.bundle_code+detail.id">
                        <td><a><img :src="detail.r_uni_product_image.image_url"></a></td>
                        <td>{{detail.name_long}}</td>
                        <td>{{detail.quantity}}</td>
                        <!-- <td>
                            <p>Rp 1.237.000</p> Rp. 745.000
                        </td> -->
                    </tr>
                </tbody>
            </table>
            <div class="pop-up-bundling-footer">
                <div class="ol-bundling-table-total">
                    <p>Total Belanja: <span>{{activeBundle ? activeBundle.r_uni_product_variant_prices.more_discount_price_rp : "Rp0"}}</span></p>
                </div>
                <div style="text-align: right;">
                    <a v-if="productsSummary.length > 0" class="ol-btn ol-btn-disable"  @click="toCart()" style="width: 100%;"><i class="fas fa-shopping-cart ol-fa"></i>
                        Masukkan Keranjang</a>
                    <a v-else class="ol-btn-disable" style="width: 100%;"><i class="fas fa-shopping-cart ol-fa"></i>
                        Masukkan Keranjang</a>
                </div>
            </div>
        </BundlePopup>
    </BaseLayout>
</template>

<script>
import {mapActions,mapGetters} from "vuex";
import BaseLayout from "@/components/landing/BaseLayout.vue";
import DetailLoader from '@/components/loading/product/Detail.vue'
import ProductList from "@/components/product/ProductList.vue"
import ReviewList from "@/components/product/ReviewList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
import SkeletonLoader from '@/components/loading/skeletonLoader'
import Popup from '@/components/popup/Popup.vue'
import BundlePopup from '@/components/popup/BundlePopup.vue'
import CarouselProduct from '@/components/home/CarouselProduct.vue'
import globalUrl from '@/util/globallUrl'
import select2 from '@/lib/select2'
import helpme from '@/util/helpme'
import lightbox from '@/assets/js/lightbox/lightbox.css';
import store from '../../store';
const UNI = globalUrl.WS_UNI;
const BASE = process.env.VUE_APP_BASE_API_URL+"/";

export default {
    name: "Bundle",
    metaInfo() {
        return{
            meta: [
                { property: 'title', content:this.product.name},
                { name: 'description', content: this.product.description ? this.product.description : ''},
                { name: 'robots', content: 'index,follow'},
                { property: 'og:url', content: window.location.origin+this.$route.fullPath}, 
                { property: 'og:title', content: this.product.name},
                { property: 'og:description', content: this.product.description ? this.product.description : ''},
                { property: 'og:image', content: this.product.r_uni_product_images && this.product.r_uni_product_images.length?this.product.r_uni_product_images[0].image_url:''},
                { property: 'og:site_name', content: 'More'},
                { property: 'og:type', content: 'website'}    
                
            ],
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components: {
        BaseLayout,
        ProductList,
        ProductLoader,
        DetailLoader,
        SkeletonLoader,
        // ReviewLoader,
		Popup,
        BundlePopup,
        ReviewList,
        CarouselProduct,
    },
    data(){
        return {
            loading:{
                // stock:false,
                detail:false,
                others:false,
                rating:false,
                review:false,
                wishlist:false,
                isOutStock:false,
                lastSeen:false,
            },
            input:{
                loading:false,
                pesan:'',
                type:null,
                message:null
            },
            tempLocations:[],
        }
    },
    computed:{
        ...mapGetters("auth", ["authenticated"]),
        ...mapGetters("product/bundle",["product","list","others","productsSummary","activeBundle","rating","location","synchProductHistory","collection"]),
        ...mapGetters('auth', ['location','authenticated']),
        ...mapGetters('profile/product', ['lastSeen']),
    },
    methods:{
        ...mapActions("chats", ["fetchData",'saveChat']),
        ...mapActions("product/bundle",["fetchProduct","fetchReviews","fetchOthers","checkStock"]),
        ...mapActions('profile/product', ['saveProductHistory','fetchLastSeen']),
        ...mapActions("transaction/cart",["save"]),
        setVariant(variant){
            this.$store.commit("product/bundle/setActiveBundle", variant);

            // this.jqUnslick();
            this.jqSlick();
            // this.jqBundleDetiailUSlick();
            this.jqBundleDetiailSlick();
            if(!this.loading.detail){
                setTimeout(() => {
                    $("a.ol-product-content-detail__desc-spec-text-type").removeClass("active");
                    $("a.ol-product-content-detail__desc-spec-text-type#"+variant.id).addClass("active");
                }, 100);
            }
        },
        async checkingStock(e,bundle,index){
            let value = _.toNumber(e.target.value);
            this.$store.commit("product/bundle/setSelectedVariants", {"bundle":bundle,"index":index,"uni_product_variants_id":value});
            if(e.target.value){
                let image = _.find(bundle.r_uni_product_images,{ uni_products_variants_id: value });
                if(image) $(".item-detail-images#detail-"+image.id).trigger("click");   
                
                $("#alert-out-stock-"+bundle.id+"-"+index).css({"visibility":"hidden"});
                $("#spinner-out-stock-"+bundle.id+"-"+index).show();
                let data = await _.find(this.productsSummary,{id:value});
                let result = await this.checkStock(data);
                $("#spinner-out-stock-"+bundle.id+"-"+index).hide();
                if(result.success){
                    // $("#button-bundling").removeClass("ol-btn-disable");
                    bundle.isOutStock = false;
                    this.$store.commit("product/bundle/setSelectedVariants", {"bundle":bundle,"index":index,"uni_product_variants_id":value});
                }else{
                    $("#alert-out-stock-"+bundle.id+"-"+index).css({"visibility":"visible"});
                    // $("#button-bundling").addClass("ol-btn-disable");
                    bundle.isOutStock = true;
                    this.$store.commit("product/bundle/setSelectedVariants", {"bundle":bundle,"index":index,"uni_product_variants_id":value});
                }
                this.disableButton();
            }
        },
        chatProduct(){
            if(this.input.pesan != ''){
                this.input.loading = true;
                let param ={
                    text:this.input.pesan,
                    uni_products_id:this.product.id
                };
                this.saveChat(param).then(result => {
                    this.input.loading = false;
                    this.input.pesan ='';
                    this.input.type = 'success';
                    this.input.message = 'Pesan berhasil dikirim';
                    // console.log(result);

                }).catch(error =>{
                    this.input.loading = false;
                    this.input.type = 'danger';
                    this.input.message = 'Pesan gagal dikirim, coba lagi nanti';
                });
            }
            
        },
        async toCart(){
            this.$store.commit('transaction/cart/plus2Cart', this.activeBundle);        
            $(".ol-addcart#cart").fadeIn().delay(2000).fadeOut();
            // this.analytics('AddToCart',{"content_name": this.activeBundle.name_long});
            this.showBundlePopup(false);
            this.synchingCart();
        },
        async synchingCart(usingQueue = true){
			if(this.authenticated){
				if(this.queue != null) clearTimeout(this.queue);
				if(usingQueue){
					this.queue = setTimeout(async () => {
						await this.save();
					}, 250);
				}else{
					await this.save();
				}
			}
		},
        disableButton(){
            let size = _.filter(this.productsSummary,{isOutStock:true}).length;
            // console.log(size);
            if(size > 0){
                $("#button-bundling").addClass("ol-btn-disable");
            }else{
                $("#button-bundling").removeClass("ol-btn-disable");
            }
        },
        toRp(number){
            return helpme.toRp(number);
        },
        
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
        
        jqUnslick(){
            if($('.ol-product-content-detail__images-list-single').hasClass("slick-initialized")) $('.ol-product-content-detail__images-list-single').slick('unslick');
            if($('.ol-product-content-detail__images-list-group').hasClass("slick-initialized")) $('.ol-product-content-detail__images-list-group').slick('unslick');      
        },

        jqSlick(){
            // console.log("slick")
            if(!this.loading.detail){
                setTimeout(() => {
                    $(".ol-product-content-detail__images-list-single").slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: !1,
                        fade: !1,
                        asNavFor: ".ol-product-content-detail__images-list-group"
                    });

                    $(".ol-product-content-detail__images-list-group").slick({
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        asNavFor: ".ol-product-content-detail__images-list-single",
                        dots: !1,
                        arrows: !0,
                        centerMode: !0,
                        focusOnSelect: !0,
                        centerPadding: "0"
                    });
                }, 100);
            }
        },

        jqBundleDetiailUSlick(){
            if($('.ol-bundling-slide').hasClass("slick-initialized")) $('.ol-bundling-slide').slick('unslick');
        },

        jqBundleDetiailSlick(){
            if(!this.loading.detail){
                setTimeout(() => {
                    $(".ol-bundling-slide").slick({
                        slidesToScroll: 1,
                        slidesToShow: 1,
                        dots: !0,
                        autoplay: !0,
                        autoplaySpeed: 5e3,
                        arrows: !0,
                        pauseOnFocus: !1,
                        responsive: [{
                            breakpoint: 481,
                            settings: {
                                arrows: !1
                            }
                        }]
                    })
                },100);
            }
        },
        async getLastSeen(){
            this.loading.lastSeen = true;
            if(this.authenticated) await this.saveProductHistory();
            await this.fetchLastSeen();
            this.loading.lastSeen = false;
        },
		copyLinkWa() {
			// console.log("copy link");
			var dummy = document.createElement('input'),
			text = window.location.href;
			document.body.appendChild(dummy);
			dummy.value = text;
			dummy.select();
			document.execCommand('copy');
			document.body.removeChild(dummy);
			$('#copy_url').prop('hidden',false);
			setTimeout(() => { $('#copy_url').prop('hidden',true); }, 1500);
    	},

        fb_share(e,o){
            return window.open("http://www.facebook.com/sharer.php?u="+encodeURIComponent(o)+"&t="+encodeURIComponent(e),"sharer","toolbar=0,status=0,width=626,height=436"),!1
        },
        currentUrl(){
            return 'https://morefurniture.id'+this.$route.fullPath;
            // return window.location.origin+this.$route.fullPath;
        },
        showPopup(show=true){  
    	    if(this.location) $("select#cities").append(new Option(this.location.city_name, this.location.city_id, true, true)).trigger('change');
            (show)? Popup.methods.show():Popup.methods.hide();
        },
        showPopup2(show=true){ 
            if(this.authenticated){
                (show)? Popup.methods.show("pesanan"):Popup.methods.hide("pesanan");
            }else{
                this.$router.push({ name: 'more-login', query:{ redirect: this.$route.fullPath }})
            } 
        },
        showBundlePopup(show=true){  
            let validate = true;
            if(show){
                $(".alert-mandatory-varian").hide();
                _.forEach(this.list, (item, key) => {
                    let qtySelected = _.sumBy(_.filter(this.productsSummary,{bundle_id:item.id}),'quantity');
                    if(qtySelected < item.qty) {
                        $("#alert-mandatory-varian-"+item.id).show();
                        $('html, body').animate({ scrollTop: $("#alert-mandatory-varian-"+item.id).closest(".ol-product-content-detail__desc").offset().top }, 500)
                        validate = false;
                        return validate;
                    }
                });
            }
            if(validate){
                (show)? BundlePopup.methods.show():BundlePopup.methods.hide();
            }
        },
        saveLocation(){
            this.$store.commit("auth/setLocation", _.find(this.tempLocations,{city_id:$("select#cities").val()}));
            this.showPopup(false);
        },
        async init(){
            this.loading.detail = true;

            this.jqScrolling2top();

            await this.fetchProduct(this.$route.params.slug);
            this.loading.detail = false;
            this.fetchOthers();
            this.getLastSeen();
            document.title = this.product.name;
            this.setVariant(this.product.r_uni_product_variants[0]);
            // this.analytics('ViewContent',{"content_name": this.product.name});
            new WOW().init();
			select2.ajax("select#cities",BASE+UNI._CITIES,'city_id','city_name',(response)=>(this.tempLocations = response));
            
        },
        
    },
    async mounted(){
        await this.init();  
        ReviewList.methods.toPage(this.product.id,1);
    },
    
    watch:{
        // 'selectedImage':function(image){
            // console.log(image);
        //     if(image) $(".ol-bundling-slide-item-"+image.id).trigger("click");   
        // },
        $route(to, from) {          
            this.init();
        }
    }
};
</script>

<style scoped>
    .ol-btn-disable{
        background-color: #aaa;
        border-color: #aaa;
        cursor: default;
        pointer-events: none;
    }
    @import '../../assets/css/bootstrap.css';
    @import '../../assets/css/productdetailnew.css';
    @import '../../assets/css/bundling2.css';
    @import '../../assets/js/lightbox/lightbox.css';
</style>
