<template>
    <div :class="'pop-up-bundling pop--bundle--'+id">
        <div class="pop-up-bundling-content">
            <div class="pop-up-bundling-content-header">
                <p>{{title}}</p>
                <span class="close-bundling" @click="hide(id)">&times;</span>
            </div>
            <div style="max-height: 400px; overflow-y: scroll; margin-bottom: 10px;">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"BundlePopup",
    props:[
        "title",
        "id",
    ],
    
    methods:{
        show(ID_AS_PARAM=null) {
            let popup_class = ID_AS_PARAM ? "pop--bundle--"+ID_AS_PARAM:'pop-up-bundling';
            
            document.getElementsByClassName(popup_class)[0].classList.add("pop-up-bundling--show"), document.querySelector("body").classList.add("popup");

		},
        hide(ID_AS_PARAM=null){
            let popup_class = ID_AS_PARAM ? "pop--bundle--"+ID_AS_PARAM:'pop-up-bundling';
            document.getElementsByClassName(popup_class)[0].classList.remove("pop-up-bundling--show"), document.querySelector("body").classList.remove("popup");
        }
    },

}
</script>

<style>

</style>